import React, { useContext } from 'react';
// Soft UI Dashboard React example components
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
import AddClientForm from 'components/ClientManagement/AddClientForm';
import Footer from 'examples/Footer';

import { Context as UserContext } from '../../b-context/usersContext';

const AddClientLayout = () => {
  const { state, updateModalOpen } = useContext(UserContext);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <AddClientForm isNew={true} />
      <Footer />
    </DashboardLayout>
  );
};

export default AddClientLayout;
