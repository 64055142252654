import React from 'react';
// import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Provider as UserProvider } from 'b-context/usersContext';
import App from 'App';

// Soft UI Dashboard React Context Provider
import { SoftUIControllerProvider } from 'context';

import { AuthProvider } from 'auth-context/auth.context';

let user = localStorage.getItem('user');
user = JSON.parse(user);

// ReactDOM.render(
//   <BrowserRouter>
//     <SoftUIControllerProvider>
//       <AuthProvider userData={user}>
//         <UserProvider>
//           <App user={user} />
//         </UserProvider>
//       </AuthProvider>
//     </SoftUIControllerProvider>
//   </BrowserRouter>,
//   document.getElementById('root')
// );

const container = document.getElementById('root');
const root = createRoot(container); // create a root

root.render(
  <BrowserRouter>
    <SoftUIControllerProvider>
      <AuthProvider userData={user}>
        <UserProvider>
          <App user={user} />
        </UserProvider>
      </AuthProvider>
    </SoftUIControllerProvider>
  </BrowserRouter>
);
