import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useAuth } from 'auth-context/auth.context';
import { Context as UserContext } from 'b-context/usersContext';
// import { useNavigate } from 'react-router-dom';

const inputStyles = `
  font-feature-settings: "dlig" on;
  font-family: Work Sans, sans-serif;
  border-radius: 12px;
  border: 1px solid rgba(219, 224, 229, 1);
  background-color: #fff;
  margin-top: 8px;
  width: 100%;
  max-width: 480px;
  color: #637587;
  font-weight: 400;
  line-height: 150%;
  padding: 16px 15px;

  @media (max-width: 991px) {
    padding-right: 20px;
  }
`;

const labelStyles = `
  color: #121417;
  font-feature-settings: "dlig" on;
  font-family: Work Sans, sans-serif;
  font-weight: 500;
  line-height: 150%;
  margin-top: 24px;

  @media (max-width: 991px) {
    max-width: 100%;
  }  
`;

const TextInput = ({ label, placeholder, value, onChange, name }) => {
  const { state } = useContext(UserContext);
  if (state.msg[name] !== undefined && state.msg[name] !== null && state.msg[name].length > 0) {
    return (
      <>
        <Label>{label}</Label>
        <Input
          type="text"
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
        />
        <p style={{ color: 'red' }}>{state.msg[name]}</p>
      </>
    );
  } else {
    return (
      <>
        <Label>{label}</Label>
        <Input
          type="text"
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
        />
      </>
    );
  }
};

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

const AddClientForm = ({ isNew }) => {
  const { user } = useAuth();
  const { state, addCustomer, getCustomers, updateMSG, updateCustomer } = useContext(UserContext);
  const [client, setClient] = useState({
    name: '',
    company: '',
    email: '',
    phone: '',
    address: '',
    address2: '',
    city: '',
    state: '',
    zip_code: '',
  });
  // needed to get the clientId from the URL
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const clientId = params.get('clientId');

  useEffect(() => {
    updateMSG([]);
    if (isNew === false) {
      //get the client info
      const client = state.customers.find((customer) => customer.id === clientId);
      console.log('client: ', client);
      setClient(client);
    }
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setClient({ ...client, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isNew === false) {
      updateMSG([]);
      //updating client
      updateCustomer(user, client).then((response) => {
        console.log('response in UpdateClinetForm: ');
        console.log(response);
        getCustomers(user);
        if (response.success === true) {
          // window.location.href = '/client-profile?clientId=' + client.id;
          // history.push('/client-profile?clientId=' + client.id);
          window.location.href = '/client-profile?clientId=' + client.id;
        }
      });
    } else {
      updateMSG([]);
      addCustomer(user, client).then((response) => {
        console.log('response in AddClinetForm: ');
        console.log(response);
        getCustomers(user);
        if (response.success === true) {
          window.location.href = '/clients';
          // history.push('/clients');
        }
      });
    }
  };
  return (
    <Container>
      <Heading>{isNew ? 'Add a new client' : 'Update a client'}</Heading>

      <TextInput
        label="Name"
        name="name"
        placeholder="Enter name"
        value={client.name}
        onChange={handleChange}
      />
      <TextInput
        label="Company Name"
        name="company"
        placeholder="Enter company name"
        value={client.company}
        onChange={handleChange}
      />
      <TextInput
        label="Email"
        name="email"
        placeholder="Enter email address"
        value={client.email}
        onChange={handleChange}
      />
      <TextInput
        label="Phone Number"
        name="phone"
        placeholder="Enter Phone Number"
        value={client.phone}
        onChange={handleChange}
      />
      <TextInput
        label="Address"
        name="address"
        placeholder="Enter address"
        value={client.address}
        onChange={handleChange}
      />
      <TextInput
        label="Address 2 (optional)"
        name="address2"
        placeholder="Enter address"
        value={client.address2}
        onChange={handleChange}
      />

      <AddressFields>
        <AddressColumn>
          {/* <Label>City</Label> */}
          <TextInput
            label="City"
            type="text"
            name="city"
            placeholder="Enter city"
            value={client.city}
            onChange={handleChange}
          />
        </AddressColumn>

        <AddressColumn>
          {/* <Label>State</Label> */}
          <TextInput
            label="State"
            type="text"
            name="state"
            placeholder="Enter state"
            value={client.state}
            onChange={handleChange}
          />
        </AddressColumn>
      </AddressFields>
      <TextInput
        label="Zip Code"
        name="zip_code"
        placeholder="Enter zip code"
        value={client.zip_code}
        onChange={handleChange}
      />
      <SubmitButton onClick={handleSubmit}>{isNew ? 'Add' : 'Update'} Client</SubmitButton>
    </Container>
  );
};

AddClientForm.propTypes = {
  isNew: PropTypes.bool.isRequired,
};

const Container = styled.div`
  align-items: center;
  display: flex;
  max-width: 512px;
  flex-direction: column;
  font-size: 16px;
  padding: 20px 0 32px;
`;

const Heading = styled.h1`
  font-feature-settings: 'dlig' on;
  align-items: start;
  align-self: stretch;
  width: 100%;
  color: #121417;
  letter-spacing: -0.8px;
  justify-content: center;
  padding: 16px;
  font:
    700 32px Work Sans,
    sans-serif;

  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const Label = styled.label`
  ${labelStyles}
`;

const Input = styled.input`
  ${inputStyles}
`;

const AddressFields = styled.div`
  justify-content: center;
  display: flex;
  margin-top: 24px;
  width: 100%;
  max-width: 480px;
  gap: 16px;
  line-height: 150%;

  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const AddressColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 0 20px;
`;

const SubmitButton = styled.button`
  all: unset;
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 20px;
  appearance: none;
  background-color: black;
  color: white;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  padding: 15px 25px;
`;

export default AddClientForm;
