import React, { useContext, useEffect, useState } from 'react';

// import DataGrid from 'react-data-grid';
import { Context as UserContext } from '../../b-context/usersContext';
import { useAuth } from '../../auth-context/auth.context';

import { HotTable } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import 'handsontable/dist/handsontable.full.min.css';

// register Handsontable's modules
registerAllModules();

const ExcelEviction = () => {
  const { user } = useAuth();
  const { state, getEvictionsByClientId, getCustomers, addEviction, updateEviction } =
    useContext(UserContext);
  const [rows, setRows] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState('');

  useEffect(() => {
    getCustomers(user);
  }, []);

  const handleRowUpdate = (changes, source) => {
    if (source !== 'loadData' && changes != null) {
      const newData = [...rows];
      console.log('changes', changes);
      changes.forEach(([row, col, oldValue, newValue]) => {
        if (!oldValue && newValue) {
          // This is potentially a new insert if the entire row was previously empty
          // Implement your logic to handle this as an insert
          console.log('Inserting new data in an empty row');
        }
        newData[row][col] = newValue;
        // console.log('newData', newData);
        // const changedEviction = newData[row];
        // console.log('changedEviction', changedEviction);
        updateEviction(user, newData[row]).then(() => {
          // getEvictionsByClientId(user, selectedCustomerId).then((evictions) => {
          //   setRows(evictions);
          // });
        });
      });
      setRows(newData);
    }
  };

  const handleChangeMenu = (e) => {
    console.log('e', e.target.value);
    setSelectedCustomerId(e.target.value);
    getEvictionsByClientId(user, e.target.value).then((evictions) => {
      setRows(evictions);
    });
  };

  const handleAddEviction = () => {
    // console.log('add eviction');
    const newEviction = {
      customer: selectedCustomerId,
      property_address: '123 Main St',
      tenants: 'John Doe',
      tenant_two: 'Jane Doe',
      tenant_three: '',
      property_zip: '46236',
      eviction_type: 'NP',
      total_owed: '10000',
      seven_day_letter: '2024-03-01',
      lock_box_code: '',
      cares_act: 'N',
      notes: '',
      property_city: 'Indianapolis',
      property_state: 'IN',
    };
    addEviction(user, newEviction).then(() => {
      getEvictionsByClientId(user, selectedCustomerId).then((evictions) => {
        setRows(evictions);
      });
    });
  };

  const columnHeaders = [
    // 'Eviction ID',
    // 'Customer ID',
    'Township',
    'Property Address',
    'Tenants',
    'Tenant 2',
    'Tenant 3',
    'Property Zip',
    'Eviction Type',
    'Total Owed',
    '7 day Letter',
    'Lock Box Code',
    'Cares Act',
    'Notes',
  ];
  const columnSettings = [
    // {
    //   data: 'eviction_id',
    //   type: 'text',
    //   readOnly: true,
    //   header: 'Eviction ID',
    // },
    // {
    //   data: 'customer',
    //   type: 'text',
    //   readOnly: true,
    //   header: 'Customer ID',
    // },
    {
      data: 'township',
      type: 'dropdown',
      source: ['Center', 'Delaware', 'Franklin', 'Warren', 'Washington', 'Wayne'],
    },
    {
      data: 'property_address',
      type: 'text',
    },
    {
      data: 'tenants',
      type: 'text',
      header: 'Tenants',
    },
    {
      data: 'tenant_two',
      type: 'text',
      header: 'Tenant Two',
    },
    {
      data: 'tenant_three',
      type: 'text',
      header: 'Tenant Three',
    },
    {
      data: 'property_zip',
      type: 'text',
      header: 'Property Zip',
    },
    {
      data: 'eviction_type',
      type: 'text',
      header: 'Eviction Type',
    },
    {
      data: 'amount_owed',
      type: 'numeric',
      header: 'Amount Owed',
      numericFormat: {
        pattern: '$0,0.00',
        culture: 'en-US', // this is the default culture, set up for USD
      },
    },
    {
      data: 'seven_day_letter',
      type: 'date',
      header: '7 day Letter',
      dateFormat: 'YYYY-MM-DD',
      correctFormat: true,
    },
    {
      data: 'lock_box_code',
      type: 'text',
      header: 'Lock Box Code',
    },
    {
      data: 'cares_act',
      type: 'text',
      header: 'Cares Act',
    },
    {
      data: 'notes',
      type: 'text',
      header: 'Notes',
    },
  ];

  return (
    <div>
      <h1>Excel Eviction</h1>
      <p>This view is responsible for handling Excel related eviction operations.</p>
      <div>
        <label htmlFor="customerSelect">Select Customer:</label>
        <select
          id="customerSelect"
          value={selectedCustomerId}
          className="rdgrid-container"
          //   onChange={(e) => getEvictionsByClientId(user, e.target.value)}
          onChange={handleChangeMenu}
        >
          <option value="">Select Customer</option>
          {state.customers.map((customer) => (
            <option key={customer.id} value={customer.id}>
              {customer.name}
            </option>
          ))}
        </select>
        {selectedCustomerId && <button onClick={handleAddEviction}>Add Eviction</button>}
      </div>
      <HotTable
        data={rows}
        rowHeaders={true}
        colHeaders={columnHeaders}
        height="auto"
        autoWrapRow={true}
        autoWrapCol={true}
        contextMenu={true}
        stretchH="all"
        columns={columnSettings}
        afterChange={handleRowUpdate}
        licenseKey="non-commercial-and-evaluation" // for non-commercial use only
      />
    </div>
  );
};

export default ExcelEviction;
