import * as React from 'react';
import styled from 'styled-components';
import { useContext, useState } from 'react';
import { useAuth } from 'auth-context/auth.context';
import { Context as UserContext } from 'b-context/usersContext';
import { Link } from 'react-router-dom';
import { TextField, Button } from '@mui/material';
import { Add } from '@mui/icons-material';
import businessProfessionalImage from '../../assets/images/business_professional_outline.png';
import InitialsAvatar from './clientComponents/InitialsAvatar';
import { useNavigate } from 'react-router-dom';

const ClientProfile = () => {
  const navigate = useNavigate();
  const { state, getCustomers, deleteCustomer } = useContext(UserContext);
  const { user } = useAuth();

  React.useEffect(() => {
    getCustomers(user);
  }, []);
  // needed to get the clientId from the URL
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const clientId = params.get('clientId');
  // console.log('clientId: ', clientId);
  // find the customer by the clientId
  const customer = state.customers.find((c) => c.id === clientId);

  if (customer === undefined) {
    return <div>No Customers...</div>;
  }

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this customer?');
    if (!confirmDelete) {
      return;
    }

    deleteCustomer(user, id).then(() => {
      getCustomers(user);
      // history.push('/clients');
      navigate('/clients');
    });
  };

  return (
    <ProfileWrapper>
      <ProfileHeader>
        <ProfileTitle>Client Profile</ProfileTitle>
        <ProfileSubtitle>Client ID: {customer.id}</ProfileSubtitle>
      </ProfileHeader>
      <ProfileContent>
        <ProfileInfo>
          <ProfileInfoContent>
            {customer ? <InitialsAvatar customer={customer} /> : null}
            <ProfileDetails>
              <ProfileName>{customer.name}</ProfileName>
              <ProfileName>{customer.company}</ProfileName>
              <ProfileContact>
                {customer.email} • {customer.phone}
              </ProfileContact>
            </ProfileDetails>
          </ProfileInfoContent>
        </ProfileInfo>
        <ProfileEditButton>
          <Link to={`/update-client?clientId=${customer.id}`}>Edit</Link>
        </ProfileEditButton>
        <ProfileDeleteButton onClick={() => handleDelete(customer.id)}>
          <DeleteButtonText>Delete</DeleteButtonText>
        </ProfileDeleteButton>
      </ProfileContent>
      <ProfileAddress>
        <AddressContent>
          <AddressIcon>
            <AddressIconImage
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/220f5362a5fec10f9dd643c28f4e36c582e03c5014b16e6ab7616044a5fa6e47?apiKey=7c630a5f5cc74d208589d164cdd061ad&"
              alt="Home Address Icon"
            />
          </AddressIcon>
          <AddressDetails>
            <AddressTitle>Address</AddressTitle>
            <AddressText>
              {customer.address} {customer.address2} {customer.city} {customer.state},{' '}
              {customer.zip_code}
            </AddressText>
          </AddressDetails>
        </AddressContent>
      </ProfileAddress>
      <ProfilePhone>
        <PhoneContent>
          <PhoneIcon>
            <PhoneIconImage
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/3860571bb361b6eccc64fa297acdc3373619b66adb6e09a229ca7255dc18f083?apiKey=7c630a5f5cc74d208589d164cdd061ad&"
              alt="Phone Icon"
            />
          </PhoneIcon>
          <PhoneNumber>{customer.phone}</PhoneNumber>
        </PhoneContent>
      </ProfilePhone>
      <ProfileEmail>
        <EmailContent>
          <EmailIcon>
            <EmailIconImage
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/fcf215661ba46af6de712f3c01124f25d0c1a875b237a044b96f0e678314015c?apiKey=7c630a5f5cc74d208589d164cdd061ad&"
              alt="Email Icon"
            />
          </EmailIcon>
          <EmailAddress>{customer.email}</EmailAddress>
        </EmailContent>
      </ProfileEmail>
      <EvictionHistorySection>
        <EvictionHeaderWrapper>
          <EvictionHeaderTitle>Eviction History</EvictionHeaderTitle>
          <EditButton>
            <Link to={`/add-eviction?clientId=${customer.id}`}>Add Eviction</Link>
          </EditButton>
        </EvictionHeaderWrapper>
        <EvictionHistoryTable>
          <EvictionHistoryHeader>
            <EvictionHistoryHeaderCell>Date</EvictionHistoryHeaderCell>
            <EvictionHistoryHeaderCell>Address</EvictionHistoryHeaderCell>
            <EvictionHistoryHeaderCell>Status</EvictionHistoryHeaderCell>
            <EvictionHistoryHeaderCell>Action</EvictionHistoryHeaderCell>
          </EvictionHistoryHeader>
          {customer.evictions.length === 0 ? (
            <p>No evictions</p>
          ) : (
            customer.evictions.map((eviction, index) => (
              <EvictionHistoryRow key={eviction.eviction_id}>
                <EvictionHistoryCell>{eviction.created_at}</EvictionHistoryCell>
                <EvictionHistoryCell>
                  {eviction.property_address} {eviction.property_city}, {eviction.property_state}{' '}
                  {eviction.property_zip}
                </EvictionHistoryCell>
                <EvictionHistoryStatusCell>
                  <EvictionHistoryStatus>{eviction.status}</EvictionHistoryStatus>
                </EvictionHistoryStatusCell>
                <EvictionHistoryCell>
                  <Button
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/eviction-view?evictionId=${eviction.eviction_id}&clientId=${customer.id}`}
                    style={{ marginRight: '8px' }}
                  >
                    View
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    component={Link}
                    to={`/update-eviction?evictionId=${eviction.eviction_id}&clientId=${customer.id}`}
                  >
                    Update
                  </Button>
                </EvictionHistoryCell>
              </EvictionHistoryRow>
            ))
          )}
        </EvictionHistoryTable>
      </EvictionHistorySection>
    </ProfileWrapper>
  );
};

const ProfileWrapper = styled.div`
  display: flex;
  max-width: 960px;
  padding-bottom: 12px;
  flex-direction: column;
`;

const ProfileHeader = styled.header`
  align-items: start;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 16px 80px 16px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
    padding-right: 20px;
  }
`;

const ProfileTitle = styled.h1`
  color: #121417;
  font-feature-settings: 'dlig' on;
  letter-spacing: -0.8px;
  font:
    700 32px Work Sans,
    sans-serif;
`;

const ProfileSubtitle = styled.p`
  color: #637587;
  font-feature-settings: 'dlig' on;
  margin-top: 12px;
  font:
    400 14px/150% Work Sans,
    sans-serif;
`;

const ProfileContent = styled.section`
  justify-content: space-between;
  align-self: center;
  display: flex;
  margin-top: 16px;
  width: 100%;
  max-width: 928px;
  gap: 20px;
  @media (max-width: 991px) {
    max-width: 100%;
    flex-wrap: wrap;
  }
`;

const ProfileInfo = styled.div`
  flex-grow: 1;
  flex-basis: auto;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ProfileInfoContent = styled.div`
  gap: 20px;
  display: flex;
  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0px;
  }
`;

const ProfileImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 31%;
  margin-left: 0px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ProfileImage = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 128px;
  max-width: 100%;
  @media (max-width: 991px) {
    margin-top: 16px;
  }
`;

const ProfileDetails = styled.div`
  display: flex;
  flex-direction: column;
  line-height: normal;
  width: 69%;
  margin-left: 20px;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const ProfileName = styled.h2`
  color: #121417;
  font-feature-settings: 'dlig' on;
  letter-spacing: -0.33px;
  font:
    700 22px Work Sans,
    sans-serif;
`;

const ProfileContact = styled.p`
  color: #637587;
  font-feature-settings: 'dlig' on;
  font:
    400 16px/150% Work Sans,
    sans-serif;
`;

const ProfileEditButton = styled.button`
  justify-content: center;
  border-radius: 12px;
  background-color: #f0f2f5;
  align-self: start;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #121417;
  font-weight: 700;
  white-space: nowrap;
  letter-spacing: 0.21px;
  line-height: 150%;
  padding: 10px 28px;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const ProfileDeleteButton = styled.button`
  justify-content: center;
  border-radius: 12px;
  background-color: #ed5562;
  border: none;
  align-self: start;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  color: #121417;
  font-weight: 700;
  white-space: nowrap;
  letter-spacing: 0.21px;
  line-height: 150%;
  padding: 10px 28px;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

const DeleteButtonText = styled.span`
  font-feature-settings: 'dlig' on;
  font-family:
    Work Sans,
    sans-serif;
  background-color: #ed5562;
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const EditButtonText = styled.span`
  font-feature-settings: 'dlig' on;
  font-family:
    Work Sans,
    sans-serif;
  background-color: #f0f2f5;
  justify-content: center;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const ProfileAddress = styled.section`
  justify-content: center;
  background-color: #fff;
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;
  padding: 12px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const AddressContent = styled.div`
  display: flex;
  padding-right: 80px;
  gap: 16px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    padding-right: 20px;
  }
`;

const AddressIcon = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #f0f2f5;
  display: flex;
  width: 48px;
  height: 48px;
  padding: 0 12px;
`;

const AddressIconImage = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const AddressDetails = styled.div`
  justify-content: center;
  display: flex;
  flex-direction: column;
  line-height: 150%;
  margin: auto 0;
`;

const AddressTitle = styled.h3`
  color: #121417;
  font-feature-settings: 'dlig' on;
  font:
    500 16px Work Sans,
    sans-serif;
`;

const AddressText = styled.p`
  color: #637587;
  font-feature-settings: 'dlig' on;
  font:
    400 14px Work Sans,
    sans-serif;
`;

const ProfilePhone = styled.section`
  justify-content: center;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 8px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const PhoneContent = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const PhoneIcon = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #f0f2f5;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0 8px;
`;

const PhoneIconImage = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const PhoneNumber = styled.p`
  color: #121417;
  font-feature-settings: 'dlig' on;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font:
    400 16px/150% Work Sans,
    sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const ProfileEmail = styled.section`
  justify-content: center;
  background-color: #fff;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 8px 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const EmailContent = styled.div`
  display: flex;
  gap: 16px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const EmailIcon = styled.div`
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #f0f2f5;
  display: flex;
  width: 40px;
  height: 40px;
  padding: 0 8px;
`;

const EmailIconImage = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 24px;
`;

const EmailAddress = styled.p`
  color: #121417;
  font-feature-settings: 'dlig' on;
  flex-grow: 1;
  flex-basis: auto;
  margin: auto 0;
  font:
    400 16px/150% Work Sans,
    sans-serif;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const EvictionHistorySection = styled.section`
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;
  padding: 0 16px;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const EvictionHistoryTable = styled.div`
  border-radius: 12px;
  border: 1px solid rgba(219, 224, 229, 1);
  background-color: #fff;
  display: flex;
  margin-top: 20px;
  flex-direction: column;
  font-size: 14px;
  color: #637587;
  font-weight: 400;
  line-height: 150%;
  padding: 1px 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const EvictionHistoryHeader = styled.div`
  background-color: #fff;
  display: flex;
  gap: 20px;
  color: #121417;
  font-weight: 500;
  white-space: nowrap;
  padding: 12px 16px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
    white-space: initial;
  }
`;

const EvictionHistoryHeaderCell = styled.div`
  font-feature-settings: 'dlig' on;
  font-family:
    Work Sans,
    sans-serif;
  flex-grow: 1;
  flex-basis: auto;
`;

const EvictionHistoryRow = styled.div`
  align-items: center;
  border-top: 1px solid rgba(229, 232, 235, 1);
  display: flex;
  gap: 20px;
  padding: 20px 16px;
  @media (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

const EvictionHistoryCell = styled.div`
  align-self: stretch;
  font-feature-settings: 'dlig' on;
  font-family:
    Work Sans,
    sans-serif;
  flex-grow: 1;
  margin: auto 0;
`;

const EvictionHistoryStatusCell = styled.div`
  align-self: stretch;
  font-feature-settings: 'dlig' on;
  font-family:
    Work Sans,
    sans-serif;
  flex-grow: 1;
  margin: auto 0;
  @media (max-width: 991px) {
    max-width: 100%;
  }
`;

const EvictionHistoryStatus = styled.span`
  align-items: center;
  border-radius: 8px;
  display: flex;
  font-size: 12px;
  justify-content: center;
  padding: 8px 12px;
  text-align: center;
  background-color: #f0f2f5;
`;

const EvictionHistoryAction = styled.button`
  align-items: center;
  border-radius: 8px;
  display: flex;
  font-size: 14px;
  justify-content: center;
  padding: 8px 12px;
  text-align: center;
  background-color: #f0f2f5;
`;

const EvictionHeaderWrapper = styled.header`
  display: flex;
  gap: 20px;
  @media (max-width: 991px) {
    flex-direction: column;
    gap: 0;
  }
`;

const EvictionHeaderTitle = styled.h2`
  color: #121417;
  font-feature-settings: 'dlig' on;
  letter-spacing: -0.27px;
  font:
    700 18px Work Sans,
    sans-serif;
  width: 50%;
  margin: 0;
  @media (max-width: 991px) {
    width: 100%;
  }
`;

const EditButton = styled.button`
  border-radius: 12px;
  background-color: #55eda1;
  color: #121417;
  font:
    700 14px Work Sans,
    sans-serif;
  letter-spacing: 0.21px;
  line-height: 150%;
  padding: 10px 28px;
  margin-left: auto;
  border: none;
  cursor: pointer;
  @media (max-width: 991px) {
    white-space: initial;
    padding: 0 20px;
  }
`;

export default ClientProfile;
