import Axios from 'axios';
import { API_SERVER } from '../config/constant';

const axios = Axios.create({
  baseURL: `${API_SERVER}`,
  headers: { 'Content-Type': 'application/json' },
});

// axios.interceptors.request.use(
//   (config) => {
//     return Promise.resolve(config);
//   },
//   (error) => Promise.reject(error)
// );

// axios.interceptors.response.use(
//   (response) => Promise.resolve(response),
//   (error) => {
//     return Promise.reject(error);
//   }
// );

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 400) {
      // console.log('error: ', error.response);
      // console.log(error.response.data.msg);
    }
    return Promise.reject(error);
  }
);

export default axios;
