import React, { useContext } from 'react';
import { Context as UserContext } from 'b-context/usersContext';
import { Alert, Button } from '@mui/material';

const DisplayError = () => {
  const { state, updateMSG } = useContext(UserContext);

  if (state.msg.length === 0) {
    return null;
  }

  const onClose = () => {
    updateMSG([]);
  };

  if (state.msg) {
    return (
      <div>
        {Object.entries(state.msg).map(([key, errors], index) => (
          <div key={index}>
            {errors.map((error, errorIndex) => (
              <Alert key={errorIndex} severity="error" style={{ marginBottom: '10px' }}>
                {key}: {error}
              </Alert>
            ))}
          </div>
        ))}
        <Button onClick={onClose}>Close</Button>
      </div>
    );
  }

  return null;
};

export default DisplayError;
