import * as React from 'react';
import { useContext, useState, useRef } from 'react';
import { useAuth } from 'auth-context/auth.context';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Context as UserContext } from 'b-context/usersContext';
import styled from 'styled-components';

const ClientCard = ({ customer }) => {
  if (customer.name === undefined) {
    return <div>No Customers...</div>;
  }
  const caseCount = customer.evictions.length;
  return (
    <div className="client-card">
      <div className="client-info">
        <div className="client-avatar">
          <img
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f9107953426e4fa7a3335a31b7fe69def5cad9b3b684000a0662e738d0c23984?apiKey=7c630a5f5cc74d208589d164cdd061ad&"
            alt={`${customer.name} avatar`}
            className="avatar-img"
          />
        </div>
        <div className="client-details">
          <h3 className="client-name">{customer.name}</h3>
          <p className="client-matters"> {customer.company}</p>
          <p className="client-matters"> {caseCount} cases</p>
          <p className="client-matters">Phone: {customer.phone}</p>
          <p className="client-matters">Email: {customer.email}</p>
        </div>
      </div>
      <div className="client-actions">
        {/* <button className="view-button">View</button> */}
        <Link to={`/client-profile?clientId=${customer.id}`} className="view-button">
          View
        </Link>
      </div>
    </div>
  );
};

ClientCard.propTypes = {
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    address: PropTypes.string.isRequired,
    evictions: PropTypes.arrayOf(PropTypes.object).isRequired,
  }).isRequired,
};

const ClientPage = () => {
  const { state, getCustomers } = useContext(UserContext);
  const { user } = useAuth();

  const [filteredCustomers, setFilteredCustomers] = useState(state.customers);
  const [searchValue, setSearchValue] = useState('');

  React.useEffect(() => {
    // console.log('ClientPage useEffect');
    getCustomers(user).then((res) => {
      console.log('res: ', res);
      if (res) {
        setFilteredCustomers(res);
      } else {
        console.log('res is undefined');
        setFilteredCustomers([]);
      }
    });
  }, []);

  const handleFilter = (e) => {
    const searchValue = e.target.value;
    setSearchValue(searchValue);
    const filtered = state.customers.filter((customer) => {
      return customer.name.toLowerCase().includes(searchValue.toLowerCase());
    });
    setFilteredCustomers(filtered);
  };

  const resetFilter = () => {
    setFilteredCustomers(state.customers);
    setSearchValue('');
  };

  return (
    <>
      <div className="clients-container">
        <header className="clients-header">
          <h1 className="clients-title">Clients</h1>
          <Link to="/add-customer" className="add-client-button">
            Add Client
          </Link>
        </header>
        <SearchSection>
          <SearchColumn>
            <ClientSearchInput
              type="text"
              id="client-search"
              placeholder="Search Clients"
              name="ClientSearch"
              required={false}
              onChange={handleFilter}
              value={searchValue}
            />
          </SearchColumn>
          <ButtonColumn>
            <SearchButton onClick={resetFilter}>Reset Filter!</SearchButton>
          </ButtonColumn>
        </SearchSection>
        {filteredCustomers &&
          filteredCustomers.map((customer) => <ClientCard key={customer.id} customer={customer} />)}
      </div>

      <style>{`
        .visually-hidden {
          position: absolute;
          width: 1px;
          height: 1px;
          padding: 0;
          margin: -1px;
          overflow: hidden;
          clip: rect(0, 0, 0, 0);
          white-space: nowrap;
          border: 0;
        }

        .clients-container {
          display: flex;
          max-width: 960px;
          padding-bottom: 80px;
          flex-direction: column;
        }

        .clients-header {
          display: flex;
          justify-content: center;
          width: 100%;
          gap: 20px;
          padding: 16px;
        }

        @media (max-width: 991px) {
          .clients-header {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }

        .clients-title {
          color: #121217;
          font-feature-settings: 'dlig' on;
          letter-spacing: -0.8px;
          flex-grow: 1;
          flex-basis: auto;
          font:
            700 32px Public Sans,
            -apple-system,
            Roboto,
            Helvetica,
            sans-serif;
        }

        .add-client-button {
          all: unset;
          display: flex;
          flex-direction: column;
          position: relative;
          appearance: none;
          background-color: black;
          color: white;
          border-radius: 4px;
          text-align: center;
          cursor: pointer;
          padding: 15px 25px;
        }

        .search-container {
          margin-left: 20px;
        }

        .client-search {
          display: flex;
          flex-direction: column;
          position: relative;
          border-radius: 3px;
          border: 1px solid #ccc;
          padding: 10px;
        }

        .client-card {
          display: flex;
          justify-content: space-between;
          background-color: #fff;
          margin-top: 12px;
          width: 100%;
          gap: 20px;
          padding: 12px 16px;
        }

        @media (max-width: 991px) {
          .client-card {
            max-width: 100%;
            flex-wrap: wrap;
          }
        }

        .client-info {
          display: flex;
          padding-right: 80px;
          gap: 16px;
        }

        @media (max-width: 991px) {
          .client-info {
            flex-wrap: wrap;
          }
        }

        .client-avatar {
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          background-color: #f0f0f5;
          width: 48px;
          height: 48px;
          padding: 0 12px;
        }

        .avatar-img {
          aspect-ratio: 1;
          object-fit: auto;
          object-position: center;
          width: 24px;
        }

        .client-details {
          display: flex;
          justify-content: center;
          flex-direction: column;
          line-height: 150%;
          margin: auto 0;
        }

        .client-name {
          color: #121217;
          font-feature-settings: 'dlig' on;
          font:
            500 16px Public Sans,
            -apple-system,
            Roboto,
            Helvetica,
            sans-serif;
        }

        .client-matters {
          color: #636387;
          font-feature-settings: 'dlig' on;
          font:
            400 14px Public Sans,
            -apple-system,
            Roboto,
            Helvetica,
            sans-serif;
        }

        .client-actions {
          display: flex;
          justify-content: center;
          border-radius: 12px;
          background-color: #f0f0f5;
          font-size: 14px;
          color: #121217;
          font-weight: 500;
          white-space: nowrap;
          line-height: 150%;
          margin: auto 0;
          padding: 6px 26px;
        }

        @media (max-width: 991px) {
          .client-actions {
            white-space: initial;
            padding: 0 20px;
          }
        }

        .view-button {
          font-feature-settings: 'dlig' on;
          font-family:
            Public Sans,
            sans-serif;
          background-color: #f0f0f5;
          justify-content: center;
          border: none;
          cursor: pointer;
        }

        @media (max-width: 991px) {
          .view-button {
            white-space: initial;
          }
        }
      `}</style>
    </>
  );
};

const SearchSection = styled.section`
  display: flex;
  gap: 20px;

  @media (max-width: 991px) {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
`;

const SearchColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 67%;
  margin-left: 20px;

  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }
`;

const ClientSearchInput = styled.input`
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
`;

const ButtonColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 33%;
  margin-left: 20px;

  @media (max-width: 991px) {
    width: 100%;
    margin-left: 0;
  }
`;

const SearchButton = styled.button`
  padding: 15px 25px;
  color: white;
  background-color: black;
  border: none;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
`;

export default ClientPage;
