import React from 'react';
import { Route, useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from 'auth-context/auth.context';
// import SweetAlert from 'react-bootstrap-sweetalert';
import PropTypes from 'prop-types';

// export const ProtectedRoute = ({ ...rest }) => {
//   const navigate = useNavigate();
//   let { user } = useAuth();
//   if (!user || !user.token || user.token === '') {
//     return (
//       <SweetAlert
//         title="You must be signed in!"
//         onCancel={() => navigate('/authentication/sign-in')}
//         onConfirm={() => navigate('/authentication/sign-in')}
//         confirmBtnCssClass={'px-5'}
//       />
//     );
//   }

//   return <Route {...rest} />;
// };

export const ProtectedRoute = ({ component: Component, ...rest }) => {
  // console.log('rest: ', rest);
  // console.log('Component: ', Component);
  // const navigate = useNavigate();
  let { user } = useAuth();
  let isAuthenticated = false;

  if (!user || !user.token || user.token === '') {
    // navigate('/authentication/sign-in');
    // return <Navigate to="/authentication/sign-in" replace />;
    isAuthenticated = false;
  } else {
    isAuthenticated = true;
  }

  // return <Component {...rest} />;
  return isAuthenticated ? (
    <Component {...rest} />
  ) : (
    <Navigate to="/authentication/sign-in" replace />
  );
};

ProtectedRoute.propTypes = {
  // children: PropTypes.node.isRequired,
  component: PropTypes.elementType.isRequired,
};
