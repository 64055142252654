import React, { useState, useEffect, useContext } from 'react';
import { Context as UserContext } from 'b-context/usersContext';
import { useAuth } from 'auth-context/auth.context';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { API_SERVER } from '../../config/constant';

const EvictionView = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { state, getEvictionById, deleteEviction } = useContext(UserContext);
  const [evictionFiles, setEvictionFiles] = useState([]);
  const [eviction, setEviction] = useState(null);
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const clientId = params.get('clientId');
  const evictionId = params.get('evictionId');

  useEffect(() => {
    if (evictionId) {
      getEvictionById(user, evictionId).then((res) => {
        // console.log('res in getEvictionById', res);
        if (res) {
          setEviction(res);
        }
      });
    }
  }, [evictionId, user]);

  const handleViewClientBtn = () => {
    // console.log('handleViewClientBtn');
    navigate(`/client-profile?clientId=${clientId}`);
  };

  const handleUpdateEvictionBtn = () => {
    // console.log('handleUpdateEvictionBtn');
    navigate(`/update-eviction?evictionId=${evictionId}&clientId=${clientId}`);
  };

  const handleGenerateEvictionFileBtn = () => {
    // console.log('handleGenerateEvictionFileBtn');
    generateEvictionPDF();
  };

  const handleDeleteEvictionBtn = () => {
    // console.log('handleDeleteEvictionBtn');
    deleteEviction(user, evictionId).then((res) => {
      // console.log('res in handleDeleteEvictionBtn', res);
      navigate(`/client-profile?clientId=${clientId}`);
    });
  };

  const generateEvictionPDF = async () => {
    try {
      // Fetch API does not support body data with GET requests. Use POST if body data is needed.
      const response = await fetch(API_SERVER + `users/make-pdf?id=${evictionId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${user.token}`,
        },
        // body: JSON.stringify({ id: evictionId }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch eviction PDF');
      }
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `Eviction_${evictionId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error generating eviction PDF:', error);
      alert('Error generating eviction PDF:', error);
    }
  };

  const newGeneratePDF = async () => {
    // console.log('newGeneratePDF');
    try {
      // Fetch API does not support body data with GET requests. Use POST if body data is needed.
      const response = await fetch(API_SERVER + `users/generate-pdf?id=${evictionId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `${user.token}`,
        },
        // body: JSON.stringify({ id: evictionId }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch eviction PDF');
      }
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.setAttribute('download', `Eviction_${evictionId}.pdf`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error generating eviction PDF:', error);
      alert('Error generating eviction PDF:', error);
    }
  };

  if (!eviction) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <Grid container spacing={2} justifyContent="left" sx={{ mb: 2 }}>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleViewClientBtn}
            style={{ backgroundColor: '#1976d2', color: 'white' }}
          >
            View Client Details
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            style={{ backgroundColor: '#dba320', color: 'white' }}
            onClick={handleUpdateEvictionBtn}
          >
            Update Eviction
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            style={{ backgroundColor: '#62aa34', color: 'white' }}
            onClick={handleGenerateEvictionFileBtn}
          >
            Generate Eviction File
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            style={{ backgroundColor: '#d32f2f', color: 'white' }}
            onClick={handleDeleteEvictionBtn}
          >
            Delete Eviction
          </Button>
        </Grid>
        <Grid item>
          <Button variant="contained" color="secondary" onClick={newGeneratePDF}>
            New Generate PDF
          </Button>
        </Grid>
      </Grid>
      <Card variant="outlined">
        <CardContent>
          <Typography gutterBottom variant="h3" component="div">
            Eviction Details
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary">
                Tenant(s): {eviction.tenants}
              </Typography>
              {/* Repeat for other properties */}
            </Grid>
            {/* Eviction Files Table */}
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary">
                Property Address: {eviction.property_address}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary">
                Property City: {eviction.property_city}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary">
                Property State: {eviction.property_state}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary">
                Property Zip: {eviction.property_zip}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary">
                Township: {eviction.township}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary">
                Date of Debt: {eviction.date_of_debt ? eviction.date_of_debt : 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary">
                Amount Owed: ${eviction.amount_owed}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary">
                Attorney Fees: ${eviction.atty_fees}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary">
                Court Costs: ${eviction.court_costs}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary">
                Total Owed: ${eviction.total_owed}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary">
                Monthly Rent: ${eviction.monthly_rent}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary">
                Date Lease Was Signed:{' '}
                {eviction.date_lease_was_signed ? eviction.date_lease_was_signed : 'N/A'}
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body2" color="textSecondary">
                Status: {eviction.status}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
};

export default EvictionView;
