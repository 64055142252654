// to make this work do this in terminal.
// export REACT_APP_BACKEND_SERVER=http://localhost:5555/api/

// let BACKEND_SERVER = null;
// if (process.env.REACT_APP_BACKEND_SERVER) {
//   console.log('process.env.REACT_APP_BACKEND_SERVER: ', process.env.REACT_APP_BACKEND_SERVER);
//   BACKEND_SERVER = process.env.REACT_APP_BACKEND_SERVER;
// } else {
//   console.log('process.env.REACT_APP_BACKEND_SERVER is undefined');
//   // BACKEND_SERVER = 'http://localhost:5555/api/';
//   // BACKEND_SERVER = 'http://192.168.1.122:5555/api/';
//   // BACKEND_SERVER = 'https://law-management-53e619b2a8da.herokuapp.com/api/';
//   BACKEND_SERVER = 'https://law.tysoft.me/api/';
//   console.log('BACKEND_SERVER: ', BACKEND_SERVER);
// }
// export const API_SERVER = BACKEND_SERVER;

// production
export const API_SERVER = '/api/';

// development
// export const API_SERVER = 'http://localhost:5555/api/';
