import React, { useContext } from 'react';
// Soft UI Dashboard React example components
import DashboardLayout from '../../examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from '../../examples/Navbars/DashboardNavbar';
// import EvictionView from 'components/EvictionView/EvictionView';
import Footer from 'examples/Footer';
import ExcelEviction from 'components/ExcelEviction/excelEviction';

import { Context as UserContext } from '../../b-context/usersContext';

const ExcelEvictionView = () => {
  //   const { state, updateModalOpen } = useContext(UserContext);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ExcelEviction />
      <Footer />
    </DashboardLayout>
  );
};

export default ExcelEvictionView;
