import React from 'react';
import { Avatar } from '@mui/material';
import PropTypes from 'prop-types';

const InitialsAvatar = ({ customer }) => {
  const getInitials = (name) => {
    let initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
  };

  return <Avatar>{getInitials(customer.name)}</Avatar>;
};

InitialsAvatar.propTypes = {
  customer: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
};

export default InitialsAvatar;
