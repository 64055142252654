import React, { useState, useContext, useEffect } from 'react';
import styled from 'styled-components';
import CurrencyInput from 'react-currency-input-field';
import PropTypes from 'prop-types';
import { useAuth } from 'auth-context/auth.context';
import { Context as UserContext } from 'b-context/usersContext';
import { useNavigate } from 'react-router-dom';
import {
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import DisplayError from 'components/DisplayError/DisplayError';

const AddUpdateEvictionForm = ({ isNew }) => {
  const {
    state,
    addEviction,
    getCustomers,
    updateMSG,
    updateEviction,
    getEvictionById,
    getCustomerById,
  } = useContext(UserContext);
  const { user } = useAuth();

  const [eviction, setEviction] = useState({
    customer: '',
    tenants: '',
    tenant_two: '',
    tenant_three: '',
    property_address: '',
    property_address2: '',
    property_city: '',
    property_state: '',
    property_zip: '',
    township: '',
    date_of_debt: new Date(Date.now()).toISOString().slice(0, 10),
    dod_plus_40: new Date(Date.now() + 40 * 24 * 60 * 60 * 1000).toISOString().slice(0, 10),
    amount_owed: 0.0,
    atty_fees: 0.0,
    court_costs: 0.0,
    total_owed: 0.0,
    damages_claimed: 0.0,
    monthly_rent: 0.0,
    date_lease_was_signed: null,
    cause_number: '',
    status: 'new request',
    eviction_hearing: null,
    damages_hearing: null,
    notes: '',
    date_filed: null,
    date_served: null,
    debt_letter_mailed: null,
    possession_order_mailed_to_client: null,
    writ_order: null,
    date_10_day_notice: null,
  });
  // new Date(Date.now()).toISOString().slice(0, 10)
  const navigate = useNavigate();
  const [client, setClient] = useState({});
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const clientId = params.get('clientId');
  const evictionId = params.get('evictionId');

  useEffect(() => {
    updateMSG([]);
    if (clientId && !client.id) {
      getCustomerById(user, clientId).then((res) => {
        console.log('res after this:', res);
        if (res.id) {
          setClient(res);
          setEviction({ ...eviction, customer: res.id });
        } else {
          // window.location.href = '/clients';
          navigate('/clients');
        }
      });
    }
    if (evictionId && !eviction.eviction_id) {
      getEvictionById(user, evictionId).then((res) => {
        if (res) {
          // res.date_of_debt = convertDateFormat(res.date_of_debt);
          // res.dod_plus_40 = convertDateFormat(res.dod_plus_40);
          if (res.eviction_hearing) {
            res.eviction_hearing = convertDateFormat(res.eviction_hearing);
          }
          if (res.damages_hearing) {
            res.damages_hearing = convertDateFormat(res.damages_hearing);
          }
          // if (res.date_lease_was_signed) {
          //   res.date_lease_was_signed = convertDateFormat(res.date_lease_was_signed);
          // }
          // if (res.date_filed) {
          //   res.date_filed = convertDateFormat(res.date_filed);
          // }
          // if (res.date_served) {
          //   res.date_served = convertDateFormat(res.date_served);
          // }
          // console.log('res after manipulations:', res);
          // if (res.date_10_day_notice) {
          //   res.date_10_day_notice = convertDateFormat(res.date_10_day_notice);
          // }
          setEviction(res);
        }
      });
    }
    const total =
      parseFloat(eviction.amount_owed) +
      parseFloat(eviction.atty_fees) +
      parseFloat(eviction.court_costs) +
      parseFloat(eviction.damages_claimed);
    setEviction((prevEviction) => ({ ...prevEviction, total_owed: total.toFixed(2) }));
  }, [eviction.amount_owed, eviction.atty_fees, eviction.court_costs, eviction.damages_claimed]);

  const convertDateFormat = (dateString) => {
    // return new Date(date).toISOString().slice(0, 16);
    // const date = new Date(dateString);
    // const padTo2Digits = (num) => num.toString().padStart(2, '0');
    // return `${date.getFullYear()}-${padTo2Digits(date.getMonth() + 1)}-${padTo2Digits(
    //   date.getDate()
    // )}T${padTo2Digits(date.getHours())}:${padTo2Digits(date.getMinutes())}:${padTo2Digits(
    //   date.getSeconds()
    // )}`;
    return dateString.slice(0, -1);
  };

  const giveDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    return formattedDate;
  };

  const handleChange = (e) => {
    setEviction({ ...eviction, [e.target.name]: e.target.value });
    // console.log(e.target.name);
    // console.log(e.target.value);
  };

  const viewEvictionBtn = () => {
    console.log('viewEvictionBtn');
    // history.push(`/eviction-view?evictionId=${evictionId}&clientId=${clientId}`);
    navigate(`/eviction-view?evictionId=${evictionId}&clientId=${clientId}`);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(eviction);
    if (isNew) {
      setEviction({ ...eviction, customer: client.id });
      addEviction(user, eviction).then((res) => {
        // console.log('res after this:', res);
        if (res.success === true) {
          // history.push(`/client-profile?clientId=${client.id}`);
          // history.push(`/eviction-view?evictionId=${res.eviction_id}&clientId=${clientId}`);
          navigate(`/eviction-view?evictionId=${res.eviction_id}&clientId=${clientId}`);
        }
      });
    } else {
      updateEviction(user, eviction).then((res) => {
        // console.log('res after this:', res);
        if (res.success === true) {
          // history.push(`/client-profile?clientId=${client.id}`);
          // history.push(`/eviction-view?evictionId=${evictionId}&clientId=${clientId}`);
          navigate(`/eviction-view?evictionId=${evictionId}&clientId=${clientId}`);
        }
      });
    }
  };

  // if (state.apiError && state.apiError.msg) {
  //   return <DisplayError />;
  // }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        {!isNew && (
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              color="primary"
              onClick={viewEvictionBtn}
              style={{ backgroundColor: '#1976d2', color: 'white' }}
            >
              View Eviction Details
            </Button>
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <Card variant="outlined" sx={{ minWidth: '100%', maxWidth: '100%' }}>
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Teneant and Property Details
              </Typography>
              <Grid container spacing={2}>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  // style={{ width: '100%', minWidth: '100%', padding: '10px' }}
                >
                  <InputLabel htmlFor="tenants">Tenants</InputLabel>
                  <TextField
                    id="tenants"
                    placeholder="Tenants"
                    value={eviction.tenants}
                    name="tenants"
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      style: { width: '100%', minWidth: '100%', padding: '10px' }, // Or minWidth: 'unset'
                    }}
                    InputLabelProps={{ shrink: false }}
                    required
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={4}
                  // style={{ width: '100%', minWidth: '100%', padding: '10px' }}
                >
                  <InputLabel htmlFor="tenants">Tenant 2</InputLabel>
                  <TextField
                    id="tenants"
                    placeholder="Tenant 2"
                    value={eviction.tenant_two}
                    name="tenant_two"
                    onChange={handleChange}
                    fullWidth
                    InputProps={{
                      style: { width: '100%', minWidth: '100%', padding: '10px' }, // Or minWidth: 'unset'
                    }}
                    InputLabelProps={{ shrink: false }}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="tenants">Tenant 3</InputLabel>
                  <TextField
                    id="tenants"
                    placeholder="Tenant 3"
                    value={eviction.tenant_three}
                    name="tenant_three"
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="property_address">Property Address</InputLabel>
                  <TextField
                    // label="Property Address"
                    placeholder="Property Address"
                    value={eviction.property_address}
                    name="property_address"
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="property_address2">Property APT/Suite</InputLabel>
                  <TextField
                    // label="Property APT/Suite"
                    placeholder="Property APT/Suite"
                    value={eviction.property_address2}
                    name="property_address2"
                    onChange={handleChange}
                    fullWidth
                    helperText="APT, Suite, etc."
                  />
                </Grid>
                {/* <Grid item xs={4}></Grid> */}
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="property_city">Property City</InputLabel>
                  <TextField
                    // label="Property City"
                    placeholder="Property City"
                    value={eviction.property_city}
                    name="property_city"
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="property_state">Property State</InputLabel>
                  <TextField
                    // label="Property State"
                    placeholder="Property State"
                    value={eviction.property_state}
                    name="property_state"
                    onChange={handleChange}
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputLabel htmlFor="property_zip">Property Zip</InputLabel>
                  <TextField
                    // label="Property Zip"
                    placeholder="Property Zip"
                    value={eviction.property_zip}
                    name="property_zip"
                    onChange={handleChange}
                    fullWidth
                    required
                    // error={!!state.msg['property_zip']} // Use !! to convert truthy value to boolean
                    // helperText={state.msg['property_zip']} // Set helperText for error message
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel id="township-label">Township</InputLabel>
                  <Select
                    labelId="township-label"
                    name="township"
                    value={eviction.township}
                    label="Township"
                    onChange={handleChange}
                    required
                  >
                    <MenuItem value="Center">Center</MenuItem>
                    <MenuItem value="Delaware">Delaware</MenuItem>
                    <MenuItem value="Franklin">Franklin</MenuItem>
                    <MenuItem value="Warren">Warren</MenuItem>
                    <MenuItem value="Washington">Washington</MenuItem>
                    <MenuItem value="Wayne">Wayne</MenuItem>
                  </Select>
                </Grid>
                <Grid item sx={12} sm={6}>
                  <InputLabel id="status-lable">Status</InputLabel>
                  <Select
                    labelId="status-lable"
                    name="status"
                    value={eviction.status}
                    label="Status"
                    onChange={handleChange}
                    fullWidth
                    required
                  >
                    <MenuItem value="new request">new request</MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Inactive">Inactive</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Eviction Dollars
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel htmlFor="monthly_rent">Monthly Rent</InputLabel>
                  <CurrencyInput
                    style={styles.styledInput}
                    name="monthly_rent"
                    value={eviction.monthly_rent} // Initial value
                    prefix="$" // Currency symbol
                    decimalScale={2} // Number of decimal places
                    onValueChange={(newValue) => {
                      setEviction({ ...eviction, monthly_rent: newValue });
                    }}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="amount_owed">Amount Owed</InputLabel>
                  <CurrencyInput
                    style={styles.styledInput}
                    name="amount_owed"
                    value={eviction.amount_owed} // Initial value
                    prefix="$" // Currency symbol
                    decimalScale={2} // Number of decimal places
                    onValueChange={(newValue) => {
                      setEviction({ ...eviction, amount_owed: newValue });
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputLabel htmlFor="atty_fees">Attorney Fees</InputLabel>
                  <CurrencyInput
                    style={styles.styledInput}
                    name="atty_fees"
                    value={eviction.atty_fees} // Initial value
                    prefix="$" // Currency symbol
                    decimalScale={2} // Number of decimal places
                    onValueChange={(newValue) => {
                      setEviction({ ...eviction, atty_fees: newValue });
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel htmlFor="court_costs">Court Costs</InputLabel>
                  <CurrencyInput
                    style={styles.styledInput}
                    name="court_costs"
                    value={eviction.court_costs} // Initial value
                    prefix="$" // Currency symbol
                    decimalScale={2} // Number of decimal places
                    onValueChange={(newValue) => {
                      setEviction({ ...eviction, court_costs: newValue });
                    }}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel htmlFor="damages_claimed">Damages Claimed</InputLabel>
                  <CurrencyInput
                    style={styles.styledInput}
                    name="damages_claimed"
                    value={eviction.damages_claimed} // Initial value
                    prefix="$" // Currency symbol
                    decimalScale={2} // Number of decimal places
                    onValueChange={(newValue) => {
                      setEviction({ ...eviction, damages_claimed: newValue });
                    }}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel htmlFor="total_owed">Total Owed</InputLabel>
                  <CurrencyInput
                    style={styles.styledInput}
                    name="total_owed"
                    value={eviction.total_owed} // Initial value
                    prefix="$" // Currency symbol
                    decimalScale={2} // Number of decimal places
                    onValueChange={(newValue) => {
                      setEviction({ ...eviction, total_owed: newValue });
                    }}
                  />
                </Grid>
                <Grid item xs={6}></Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Card variant="outlined">
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                Eviction Dates
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel htmlFor="date_of_debt">Date of Debt</InputLabel>
                  {/* <TextField
                    // label="Date of Debt"
                    type="datetime-local"
                    placeholder="Date of Debt"
                    inputFormat="yyyy-MM-DD'T'HH:mm"
                    value={eviction.date_of_debt}
                    name="date_of_debt"
                    // onChange={handleChange}
                    onChange={(e) => {
                      // setEviction({ ...eviction, [e.target.name]: e.target.value });
                      const newDate = new Date(e.target.value);
                      // console.log('e.target.value', e.target.value);
                      const dodPlus40 = new Date(newDate.getTime() + 40 * 24 * 60 * 60 * 1000)
                        .toISOString()
                        .slice(0, 16);
                      setEviction({
                        ...eviction,
                        dod_plus_40: dodPlus40,
                        date_of_debt: e.target.value,
                      });
                    }}
                    fullWidth
                  /> */}
                  <TextField
                    type="date"
                    name="date_of_debt"
                    value={giveDate(eviction.date_of_debt)}
                    inputFormat="YYYY/MM/DD"
                    onChange={(e) => {
                      // setEviction({ ...eviction, date_of_debt: e.target.value });
                      const newDate = new Date(e.target.value);
                      const dodPlus40 = new Date(newDate.getTime() + 40 * 24 * 60 * 60 * 1000)
                        .toISOString()
                        .slice(0, 16);
                      setEviction({
                        ...eviction,
                        dod_plus_40: dodPlus40,
                        date_of_debt: e.target.value,
                      });
                    }}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="dod_plus_40">Date of Debt + 40 days</InputLabel>
                  {/* <TextField
                    // label="Date of Debt + 40 days"
                    type="datetime-local"
                    inputFormat="yyyy-MM-DD'T'HH:mm"
                    placeholder="Date of Debt + 40 days"
                    value={eviction.dod_plus_40}
                    name="dod_plus_40"
                    onChange={handleChange}
                    fullWidth
                  /> */}
                  <TextField
                    type="date"
                    name="dod_plus_40"
                    inputFormat="YYYY/MM/DD"
                    value={giveDate(eviction.dod_plus_40)}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel htmlFor="eviction_hearing">Eviction Hearing</InputLabel>
                  <TextField
                    // label="Eviction Hearing"
                    type="datetime-local"
                    inputFormat="yyyy-MM-DD'T'HH:mm"
                    placeholder="Eviction Hearing"
                    value={eviction.eviction_hearing}
                    name="eviction_hearing"
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="damages_hearing">Damages Hearing</InputLabel>
                  <TextField
                    // label="Damages Hearing"
                    type="datetime-local"
                    inputFormat="yyyy-MM-DD'T'HH:mm"
                    placeholder="Damages Hearing"
                    value={eviction.damages_hearing}
                    name="damages_hearing"
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel htmlFor="date_lease_was_signed">Date Lease Was Signed</InputLabel>
                  {/* <TextField
                    // label="Date Lease Was Signed"
                    type="datetime-local"
                    inputFormat="yyyy-MM-DD'T'HH:mm"
                    placeholder="Date Lease Was Signed"
                    value={eviction.date_lease_was_signed}
                    name="date_lease_was_signed"
                    onChange={handleChange}
                    fullWidth
                  /> */}
                  <TextField
                    type="date"
                    name="date_lease_was_signed"
                    inputFormat="YYYY/MM/DD"
                    value={giveDate(eviction.date_lease_was_signed)}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={2}></Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel htmlFor="date_filed">Date Filed</InputLabel>
                  {/* <TextField
                    // label="Date Lease Expires"
                    type="datetime-local"
                    placeholder="Date Filed"
                    value={eviction.date_filed}
                    name="date_filed"
                    onChange={handleChange}
                    fullWidth
                  /> */}
                  <TextField
                    type="date"
                    name="date_filed"
                    inputFormat="YYYY/MM/DD"
                    value={giveDate(eviction.date_filed)}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <InputLabel htmlFor="date_served">Date Served</InputLabel>
                  {/* <TextField
                    // label="Date Served"
                    type="datetime-local"
                    placeholder="Date Served"
                    value={eviction.date_served}
                    name="date_served"
                    onChange={handleChange}
                    fullWidth
                  /> */}
                  <TextField
                    type="date"
                    name="date_served"
                    value={giveDate(eviction.date_served)}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel htmlFor="cause_number">Cause Number</InputLabel>
                  <TextField
                    // label="Cause Number"
                    placeholder="Cause Number"
                    value={eviction.cause_number}
                    name="cause_number"
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <InputLabel htmlFor="date_10_day_notice">Date 10 Day Notice</InputLabel>
                  {/* <TextField
                    // label="Date 10 Day Notice"
                    type="datetime-local"
                    // inputFormat="yyyy-MM-DD'T'HH:mm"
                    placeholder="Date 10 Day Notice"
                    value={eviction.date_10_day_notice}
                    name="date_10_day_notice"
                    onChange={handleChange}
                    fullWidth
                  /> */}
                  <TextField
                    type="date"
                    name="date_10_day_notice"
                    value={giveDate(eviction.date_10_day_notice)}
                    onChange={handleChange}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Grid container spacing={2}>
            <DisplayError />
          </Grid>
        </Grid>
        {state.msg.length > 0 ? (
          <Grid item xs={12}>
            <Card variant="outlined">
              <CardContent>
                <Typography gutterBottom variant="h6" component="div">
                  Error Messages
                </Typography>
                <DisplayError />
              </CardContent>
            </Card>
          </Grid>
        ) : null}
        <Grid item xs={12} md={12}>
          <Button variant="contained" type="submit">
            {isNew ? 'Add' : 'Update'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
};

AddUpdateEvictionForm.propTypes = {
  isNew: PropTypes.bool.isRequired,
};

const styles = {
  styledInput: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    height: '2.6rem',
    padding: '1rem 1.5rem',
    border: '1px solid #ccc',
    borderRadius: '4px',
    backgroundColor: '#fff',
    color: '#000',
    transition: 'border-color, box-shadow',
    outline: 'none',
    '&:focus': {
      borderColor: '#000',
      boxShadow: '1',
    },
  },
};

export default AddUpdateEvictionForm;
