import createDataContext from './createDataContext';
import axios from '../api/index';

// const [userToken, setUserToken ] = useState(null);
// const [apiError, setApiError] = useState('');

const UserReducer = (state, action) => {
  switch (action.type) {
    case 'update_customers':
      return { ...state, customers: action.payload };
    case 'api_error':
      return { ...state, apiError: action.payload };
    case 'update_modal_open':
      return { ...state, isModalOpen: action.payload };
    case 'update_edit_modal':
      return { ...state, isEditModalOpen: action.payload };
    case 'update_msg':
      return { ...state, msg: action.payload };
    case 'update_evictions':
      return { ...state, evictions: action.payload };
    default:
      return state;
  }
};

const getCustomers = (dispatch) => {
  return async (user) => {
    try {
      const response = await axios.get('/users/customer', {
        headers: {
          Authorization: `${user.token}`,
        },
      });
      //   console.log('response: ', response);
      dispatch({ type: 'update_customers', payload: response.data });
      return response.data;
    } catch (error) {
      console.error('Error retrieving customers:', error);
      return [];
      // return error.response.data;
      // return error;
    }
  };
};

const deleteCustomer = (dispatch) => {
  return async (user, id) => {
    try {
      const response = await axios.delete(`/users/customer/${id}`, {
        headers: {
          Authorization: `${user.token}`,
        },
      });
      // console.log('response: ', response);
      // return response.data;
    } catch (error) {
      console.error('Error deleting customer:', error);
      // dispatch({ type: 'update_msg', payload: error.response.data.msg });
      // return error.response.data;
    }
  };
};

const addCustomer = (dispatch) => {
  return async (user, customer) => {
    try {
      const response = await axios.post('/users/customer?sort=name', customer, {
        headers: {
          Authorization: `${user.token}`,
        },
      });
      return response.data;
    } catch (error) {
      dispatch({ type: 'update_msg', payload: error.response.data.msg });
      return error.response.data;
    }
  };
};

const updateCustomer = (dispatch) => {
  return async (user, customer) => {
    try {
      const response = await axios.put(`/users/customer/${customer.id}`, customer, {
        headers: {
          Authorization: `${user.token}`,
        },
      });
      console.log('response: ', response);
      //   getCustomers();
      return response.data;
    } catch (error) {
      console.error('Error updating customer:', error);
      dispatch({ type: 'update_msg', payload: error.response.data.msg });
      return error.response.data;
    }
  };
};

const updateModalOpen = (dispatch) => {
  return (isOpen) => {
    dispatch({ type: 'update_modal_open', payload: isOpen });
  };
};

const updateEditModalOpen = (dispatch) => {
  return (isOpen) => {
    dispatch({ type: 'update_edit_modal', payload: isOpen });
  };
};

const updateMSG = (dispatch) => {
  return (msg) => {
    dispatch({ type: 'update_msg', payload: msg });
  };
};

const addEviction = (dispatch) => {
  return async (user, eviction) => {
    try {
      const response = await axios.post('/users/eviction', eviction, {
        headers: {
          Authorization: `${user.token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.log('error: ', error);
      dispatch({ type: 'update_msg', payload: error.response.data.msg });
      return error.response.data;
    }
  };
};

const updateEviction = (dispatch) => {
  return async (user, eviction) => {
    try {
      const response = await axios.put(`/users/eviction/${eviction.eviction_id}`, eviction, {
        headers: {
          Authorization: `${user.token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (!error.response) {
        console.log('API did not respond with an error object.');
        dispatch({ type: 'update_msg', payload: 'Unknown error occurred' });
      } else if (!error.response.data.msg) {
        console.log('API response does not contain a message.');
        dispatch({ type: 'update_msg', payload: 'Error occurred without a specific message' });
      }
      // console.log('error: ', error);
      // dispatch({ type: 'update_msg', payload: error.response.data.msg });
      return error.response.data;
    }
  };
};

const deleteEviction = (dispatch) => {
  return async (user, id) => {
    try {
      const response = await axios.delete(`/users/eviction/${id}`, {
        headers: {
          Authorization: `${user.token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error deleting eviction:', error);
      return error.response.data;
    }
  };
};

const getEvictionById = (dispatch) => {
  return async (user, id) => {
    try {
      const response = await axios.get(`/users/eviction/${id}`, {
        headers: {
          Authorization: `${user.token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error retrieving eviction:', error);
      return error.response.data;
    }
  };
};

const getCustomerById = (dispatch) => {
  return async (user, id) => {
    try {
      const response = await axios.get(`/users/customer/${id}`, {
        headers: {
          Authorization: `${user.token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error retrieving customer:', error);
      return error.response.data;
    }
  };
};

const updateApiError = (dispatch) => {
  return (error) => {
    dispatch({ type: 'update_api_error', payload: error });
  };
};

const getEvictionsByClientId = (dispatch) => {
  return async (user, clientid) => {
    try {
      const response = await axios.get(`/users/eviction?customer=${clientid}`, {
        headers: {
          Authorization: `${user.token}`,
        },
      });
      dispatch({ type: 'update_evictions', payload: response.data });
      return response.data;
    } catch (error) {
      console.error('Error retrieving evictions:', error);
      return error.response.data;
    }
  };
};

export const { Context, Provider } = createDataContext(
  UserReducer,
  {
    getCustomers,
    deleteCustomer,
    addCustomer,
    updateModalOpen,
    updateCustomer,
    updateEditModalOpen,
    updateMSG,
    addEviction,
    updateEviction,
    getEvictionById,
    getCustomerById,
    deleteEviction,
    updateApiError,
    getEvictionsByClientId,
  },
  {
    customers: [],
    apiError: '',
    isModalOpen: false,
    isEditModalOpen: false,
    msg: [],
    evictions: [],
  }
);
